import React from "react"
import styled from "styled-components"

const StyledPagination = styled.div`
  display: flex;
  width: 220px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  p {
    margin: 0 15px;
  }
`

// Pagination button component
const PaginateButton = ({ name, pageNumber, setPageNumber, numPages }) => {
  const handleClick = pgNumber => setPageNumber(pgNumber)

  if (name === "previous" && pageNumber > 1) {
    return (
      <button
        onClick={() => {
          handleClick(pageNumber - 1)
        }}
        name="previous"
      >
        ＜ 前へ
      </button>
    )
  }

  if (name === "next" && pageNumber < numPages) {
    return (
      <button onClick={() => handleClick(pageNumber + 1)} name="next">
        次へ ＞
      </button>
    )
  }

  return null
}

const Pagination = ({ pageNumber, setPageNumber, numPages }) => {
  return (
    <StyledPagination>
      <PaginateButton
        name="previous"
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      <p>
        {" "}
        {pageNumber} / {numPages}
      </p>
      <PaginateButton
        name="next"
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        numPages={numPages}
      />
    </StyledPagination>
  )
}

export default Pagination
